import { Observer } from 'mobx-react';
import { handleFindRaces, handleMilesRedirect } from 'modules/header';
import { isMiles } from 'modules/header/selectors';
import { headerStore } from 'modules/header/stores';
import { Button as ButtonMiles } from 'modules/miles/shared/components';
import { DesktopSearch } from 'modules/search/containers';
import * as React from 'react';
import styled from 'styled-components';

import { snackStore } from 'src/stores';
import { Button } from 'src/styledComponents/Button';
import { Field as NavigationField } from 'src/styledComponents/Navigation/NavigationTypes';

import { Hide, Show } from 'components/condition';

import { AuthMenuTypes } from '../../../types';
import { AuthMenu } from '../AuthMenu';
import { LanguageSelect } from '../LanguageSelect';
import { Props as LanguageSelectTypes } from '../LanguageSelect/LanguageSelectTypes';
import { Logo, NavigationMenu } from '../index';
import { HeaderWrapper, SearchWrapper, TopWrapper } from './styled';

type Props = {
  navList: NavigationField[];
  language: LanguageSelectTypes;
  auth: AuthMenuTypes;

  withBackground?: boolean;
  slim?: boolean;
  fullWidth?: boolean;
  hide?: boolean;
};

const StyledButton = styled(Button)`
  padding: 12px 18px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.32px;
    text-transform: capitalize;
  }
`;

export const Header: React.FC<Props> = (props) => {
  const { navList, language, auth, withBackground, slim, hide, fullWidth } = props;

  return (
    <Observer>
      {() => {
        const isSnackShown = Boolean(!snackStore.isAllSnacksHidden && snackStore.values.length);
        const variant = headerStore.value?.variant;

        return (
          <HeaderWrapper hide={hide} isSnackShown={isSnackShown} variant={variant}>
            <TopWrapper fullWidth={fullWidth} isMiles={isMiles.get()}>
              <div className='logo-block'>
                <Logo />
              </div>
              <Hide if={isMiles.get()}>
                <SearchWrapper>
                  <DesktopSearch />
                </SearchWrapper>
              </Hide>
              <div className='right-block'>
                <Hide if={isMiles.get()}>
                  <NavigationMenu navList={navList} />
                  <LanguageSelect {...language} isDark />
                  <StyledButton variant='primary' size='small' onClick={handleMilesRedirect}>
                    Go to miles
                  </StyledButton>
                </Hide>
                <Show if={isMiles.get()}>
                  <ButtonMiles className='border' size='l' message='subtle' mode='dark' onClick={handleFindRaces}>
                    Find Races
                  </ButtonMiles>
                </Show>
                <AuthMenu {...auth} isMiles={isMiles.get()} />
              </div>
            </TopWrapper>
          </HeaderWrapper>
        );
      }}
    </Observer>
  );
};
