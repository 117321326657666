import axios from 'axios';

import { API_URL, API_SEARCH, API_SEARCH_ROUTE, API_RACES_URL, API_V2_URL } from 'src/constants';
import { mapRaces } from 'src/modules/home/utils';
import { action, request } from 'src/utils';

import { GET_RACES_LIST, GET_MAP_POINTS, GET_RACE_CARD } from '../constants';

import { mapPoints, mapRace } from '../utils';

import { filterStore, racesStore, metaStore, mapPointsStore, mapCardStore } from '../stores';

import { latinizeFilters, requestFilters } from '../selectors';

export class RacesService {
  @request({ action: GET_RACES_LIST })
  static async getRacesRequest(page: number, limit: number, filters: AnyObject): Promise<any[]> {
    const params = {
      from: (page - 1) * limit,
      limit,
    };

    return axios.post(
      `${API_URL}${API_SEARCH}`,
      {
        length_to: 1000000,
        page,
        ...filters,
      },
      { params },
    );
  }

  @action({ action: GET_RACES_LIST })
  static async getRaces() {
    const { page, limit } = filterStore.value!;
    const filters = await latinizeFilters();

    const [success, response] = await RacesService.getRacesRequest(page, limit, filters);

    if (success) {
      const data = mapRaces(response.data.data);
      racesStore.setValue(data);
      metaStore.setValue(response.data.meta.pagination);
    }
  }

  @request({ action: GET_MAP_POINTS })
  static async getMapPointsRequest(filters: AnyObject): Promise<any[]> {
    return axios.post(`${API_URL}${API_SEARCH_ROUTE}`, {
      ...filters,
      page: 1,
    });
  }

  @action({ action: GET_MAP_POINTS })
  static async getMapPoints() {
    const filters = requestFilters.get();

    const [success, response] = await RacesService.getMapPointsRequest(filters);

    if (success) {
      const isDateFilter = Boolean(filters.date_from);
      const data = mapPoints(response.data.data, isDateFilter);
      mapPointsStore.setValue(data);
    }
  }

  @request({ action: GET_RACE_CARD })
  static async getRaceRequest(id: number): Promise<any[]> {
    const params = {
      with: 'distances;location',
    };
    return axios.get(`${API_V2_URL}${API_RACES_URL}/${id}`, { params });
  }

  @action({ action: GET_RACE_CARD })
  static async getRace(id: number) {
    const [status, response] = await RacesService.getRaceRequest(id);

    if (status) {
      const data = mapRace(response.data.data);
      mapCardStore.setValue(data);
    }
  }
}
