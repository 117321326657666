import trim from 'lodash/trim';
import { inject, observer } from 'mobx-react';
import { appleLoginAction, facebookLoginAction, googleLoginAction } from 'modules/auth/actions';
import AuthPageTemplate, { ButtonWrapper, Controls, FormField, StyledDivider } from 'modules/auth/components/AuthPageTemplate';
import SocialButtons from 'modules/auth/components/SocialButtons';
import * as React from 'react';
import styled from 'styled-components';

import { LOGIN, RESOLUTIONS, ROUTER, SIGNUP } from 'src/constants';
import { Checkbox } from 'src/styledComponents/Checkbox';
import { Divider } from 'src/styledComponents/Divider';
import { Link } from 'src/styledComponents/Link';
import { TextField } from 'src/styledComponents/TextField';
import { BodyText } from 'src/styledComponents/Typography';

import { withErrorClean } from 'hocs';

import { Show } from 'components/condition';

import { Errors, history, validation, t } from 'utils';

import { AuthService } from 'services';

import { progressStore, windowSize } from 'stores';

import TermsAndConditions from './TermsAndConditions';

const PasswordTip = styled.div`
  margin-top: 8px;
  color: ${(props) => props.theme.main.colors.clay3};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03em;
`;

const SignInBlock = styled(BodyText)`
  color: ${(props) => props.theme.main.colors.clay2};
  font-size: 16px;
  a {
    color: ${(props) => props.theme.main.colors.clay0};
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  @media (max-width: ${RESOLUTIONS.small}px) {
    margin-bottom: 70px;
    font-size: 14px;
    a {
      font-size: 16px;
    }
  }
`;

const EmailWarning = styled.div`
  a {
    color: ${(props) => props.theme.main.colors.clay0};
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
  }
`;

const CheckBoxField = styled.div`
  margin-bottom: 16px;
`;

const signUpInput = {
  email: {
    email: {
      message: () => t.staticAsString('login.email validation.notValid' as TranslationLockedKeys),
    },
  },
  password: {
    presence: true,
    length: {
      minimum: 6,
      tooShort: () => t.staticAsString('validation.shortPass'),
    },
  },
};

@withErrorClean(SIGNUP)
@inject('errorsStore', 'sessionStore')
@observer
export class SignUp extends React.Component<SignUpPropsType, SignupType> {
  action: string;
  service: AuthService;
  errorsHandler: Errors;

  static defaultProps = {
    errorsStore: null as any,
    sessionStore: null as any,
  };

  constructor(props: SignUpPropsType) {
    super(props);
    this.action = SIGNUP;
    this.errorsHandler = new Errors(SIGNUP);
    this.service = new AuthService();

    this.state = {
      email: '',
      password: '',
      password_confirmation: '',
      isAuth: props.sessionStore.isAuth,
      is_organizer: false,
      subscribe_for_newsletter: false,
    };
  }

  static getDerivedStateFromProps(props: SignUpPropsType, state: SignupType) {
    return {
      isAuth: props.sessionStore.isAuth,
    };
  }

  componentDidUpdate(prevProps: SignUpPropsType, prevState: SignupType) {
    if (this.state.isAuth && !prevState.isAuth) {
      history.push(ROUTER.SETTINGS);

      if (this.props.closeModal) {
        this.props.closeModal();
      }

      return false;
    }
  }

  onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    progressStore.log(LOGIN, 'progress');
    if (validation(this.action, this.state, signUpInput)) {
      const status = await this.service.registration({
        ...this.state,
        email: trim(this.state.email),
      });

      if (status) {
        history.push(ROUTER.SETTINGS);
      }
    }
    progressStore.log(LOGIN, 'completed');
  };

  onChange = (arg0, e: React.SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === 'email') {
      return this.setState({
        ...this.state,
        email: trim(e.currentTarget.value),
      });
    }
    if (e.currentTarget.name === 'password') {
      return this.setState({
        ...this.state,
        password_confirmation: e.currentTarget.value,
        password: e.currentTarget.value,
      });
    }
    if (arg0.name === 'is_organizer') {
      return this.setState({
        ...this.state,
        is_organizer: Boolean(arg0.value),
        ...(!arg0.value && { subscribe_for_newsletter: false }),
      });
    }
    if (arg0.name === 'subscribe_for_newsletter') {
      return this.setState({
        ...this.state,
        subscribe_for_newsletter: Boolean(arg0.value),
      });
    }
  };

  hasError = (name: string): string | React.ReactNode => {
    if (name === 'email' && this.errorsHandler.hasError(name) === 'The email is already registered!') {
      return (
        <EmailWarning>
          It seems you are already registered at RaceID.com &nbsp;
          <Link className='link' to={ROUTER.SIGNIN} ATN='signin'>
            {t('navigation.signInLink')}
          </Link>
        </EmailWarning>
      );
    }
    if (name === 'email' && this.errorsHandler.hasError(name) === 'Email is not valid' && this.state.email.length === 0) {
      return t.staticAsString('validation.emailRequired');
    }

    return this.errorsHandler.hasError(name);
  };

  render(): React.ReactNode {
    const { email, password, is_organizer, subscribe_for_newsletter } = this.state;
    const { sessionStore } = this.props;
    const isMobile = windowSize.isLessThan('small');
    // for mobx
    sessionStore.isAuth;

    return (
      <AuthPageTemplate title={t.staticAsString('register.title')} testAnchorContainer={'SignUp'}>
        <form onSubmit={this.onSubmit}>
          <FormField>
            <TextField
              name='email'
              value={email}
              label={t.staticAsString('login.email')}
              requiredMark
              errorMessage={this.hasError('email')}
              isWarning={this.errorsHandler.hasError('email') === 'The email is already registered!'}
              placeholder={t.staticAsString('login.email.placeholder')}
              onChange={this.onChange}
            />
          </FormField>
          <FormField>
            <TextField
              name='password'
              type='password'
              value={password}
              label={t.staticAsString('login.password')}
              requiredMark
              errorMessage={this.hasError('password')}
              placeholder={t.staticAsString('login.password')}
              onChange={this.onChange}
            />
            {password?.length < 6 && !this.hasError('password') && <PasswordTip>{'Minimum of 6 characters'}</PasswordTip>}
          </FormField>

          <Controls>
            <ButtonWrapper size='large' variant='primary' type='submit' fullWidth ATN='submit'>
              {t('login.continue')}
            </ButtonWrapper>
            {/* //! Move this logic to the Profile later */}
            {/* <CheckBoxField>
              <Checkbox
                labelPosition='right'
                name='is_organizer'
                id='is_organizer'
                value={is_organizer}
                label={t.staticAsString('register.areYouOrganizer')}
                labelBodyVariant='body2'
                onChange={this.onChange}
              />
            </CheckBoxField>
            <Show if={is_organizer}>
              <CheckBoxField>
                <Checkbox
                  labelPosition='right'
                  name='subscribe_for_newsletter'
                  id='subscribe_for_newsletter'
                  value={subscribe_for_newsletter}
                  label={t.staticAsString('register.subscribe')}
                  labelBodyVariant='body2'
                  onChange={this.onChange}
                />
              </CheckBoxField>
            </Show> */}

            <TermsAndConditions />

            <StyledDivider>
              <Divider light />
              <span>{t('login.or')}</span>
              <Divider light />
            </StyledDivider>

            <SocialButtons
              onFacebookClick={(responseData) => facebookLoginAction(responseData, this.props.closeModal)}
              onGoogleClick={(responseData) => googleLoginAction(responseData, this.props.closeModal)}
              onAppleClick={(responseData) => appleLoginAction(responseData, this.props.closeModal)}
            />
          </Controls>
        </form>
        <SignInBlock variant='body1'>
          <span>{t('login.alreadyHaveAcc')}</span>
          &nbsp;
          <Link className='link' to={ROUTER.SIGNIN} ATN='signin'>
            {t('navigation.signInLink')}
          </Link>
        </SignInBlock>
      </AuthPageTemplate>
    );
  }
}
