// In case of registration 422

export const enum RegistrationStepEnum {
  registration = 'registration',
  confirmation = 'confirmation',
}

export const enum PaymentMethod {
  creditCard = 1,
  swish = 2,
}

export const PaymentMethodNames = {
  [PaymentMethod.creditCard]: 'Credit Card',
  [PaymentMethod.swish]: 'Swish',
};

export enum RegistrationFields {
  email = 'email',
  firstname = 'firstname',
  lastname = 'lastname',
  gender = 'gender',
  telephone = 'telephone',
  company = 'company',
  emergency_name = 'emergency_name',
  emergency_phone = 'emergency_phone',
  diseases = 'diseases',
  blood_type = 'blood_type',
  address = 'address',
  city = 'city',
  country_id = 'country_id',
  nationality_id = 'nationality_id',
  post_code = 'post_code',
  birthday = 'birthday',
  union = 'union',
  shoe_size = 'shoe_size',
  shirt_size = 'shirt_size',
  external_swimrun_id = 'external_swimrun_id',
  squad_id = 'squad_id',
}

export const enum AdditionalRegistrationFields {
  class_id = 'class_id',
  wave_id = 'wave_id',
  discipline_id = 'discipline_id',
  coupon_code = 'coupon_code',
  refund_protect = 'refund_protect',
}

/**
 * @description
 * Fields that are not related to the registration directly
 * but exists in the form
 */
export const enum OtherFields {
  password = 'password',
  password_confirmation = 'password_confirmation',
  terms = 'terms',
  open = 'open',
  _id = '_id',
  payment_method = 'payment_method',
}

/**
 * @description
 * Fields that are related to the team object
 */
export const enum TeamFields {
  team_name = 'name',
}

export const enum CustomFieldTypes {
  textfield = 'textfield',
  date = 'date',
  time = 'time',
  checkbox = 'checkbox',
  drop_down = 'drop_down',
  radio = 'radio',
  extra = 'extra',
}

export const enum fieldIdStatic {
  groupRegistrations = 'groupRegistrations',
  teamMembers = 'teamMembers',
  currentDistance = 'currentDistance',
  terms = 'terms',
  customFieldsKey = 'custom',
  customFieldValueIdPrefix = 'custom_field_',
}

export const DISTANCE_SELECTOR_CONTAINER = '';

export const enum SNACK_SCOPES {
  blockedRegistration = 'blockedRegistration',
  payment = 'payment',
}

export const FORM_NEW_SQUAD = 'formNewSquad';

export const enum RegistrationStatus {
  pending = 1,
  completed = 2,
  canceled = 3,
}

export const enum INITIAL_QUANTITY {
  racerParticipant = 1,
  teamMember = 2,
}
