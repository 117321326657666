import { isEmpty } from 'lodash';
import React, { ReactElement } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTER, STATUS_READY } from 'src/constants';
import { racesStore } from 'src/stores';
import { HorizontalScroll } from 'src/styledComponents/HorizontalScroll';
import { Navigation, Variant } from 'src/styledComponents/Navigation';
import { t } from 'src/utils';

import { handleClickNav } from '../actions';

type Props = RouteComponentProps & {};

const StyledNavigation = styled(Navigation)`
  a,
  span {
    padding: 16px 0;
  }
  gap: 40px;
`;

const RaceNavigationTabsComponent = (props: Props): ReactElement | null => {
  const racesModel: AnyObject = racesStore.modelSelectedValue || {};
  const value = racesModel && racesModel.value;

  if (!value) {
    return null;
  }

  const raceTabs = () => {
    const race = racesStore.selected;
    const atLeastOneDistanceHasShowStartlist = Boolean(race?.distances.filter((distance) => distance.show_startlist).length);
    const atLeastOneDistanceHasShowResults = Boolean(race?.distances.filter((distance) => distance.show_results).length);
    const hasToShowStartlist = !!race?.show_startlist && atLeastOneDistanceHasShowStartlist;
    const hasToShowResults = !!race?.show_results && atLeastOneDistanceHasShowResults;
    const hasRedirectUrl = !!(race || {}).race_url;
    const id = props.match!.params.id;

    const urls = [
      {
        showInDesktop: true,
        titleLink: t.staticAsString('race.register'),
        link: generatePath(ROUTER.RACES_ABOUT, { id }),
        id: 'aboutUs',
        ATN: 'register',
      },
      {
        showInDesktop: true,
        titleLink: 'Information',
        link: generatePath(ROUTER.RACES_INFORMATION, { id }),
        id: 'information',
        ATN: 'information',
      },
    ];

    if (!isEmpty(race?.location)) {
      urls.push({
        showInDesktop: true,
        titleLink: t.staticAsString('race.mapRace'),
        link: generatePath(ROUTER.RACES_MAP, { id }),
        id: 'map',
        ATN: 'map',
      });
    }

    if (!hasRedirectUrl && hasToShowStartlist) {
      urls.push({
        showInDesktop: true,
        titleLink: t.staticAsString('race.startList'),
        link: generatePath(ROUTER.RACES_START_LIST, { id }),
        id: 'list',
        ATN: 'startList',
      });
    }

    // Here was hasNotReadyDistances condition
    // You can revert it by checking annotation of this comment
    if (!hasRedirectUrl && hasToShowResults) {
      urls.push({
        showInDesktop: true,
        titleLink: t.staticAsString('race.result'),
        link: generatePath(ROUTER.RACES_RESULT, { id }),
        id: 'result',
        ATN: 'result',
      });
    }

    return urls;
  };

  return (
    <HorizontalScroll>
      <StyledNavigation
        className='navigation'
        closeSideBar={handleClickNav}
        variant={Variant.sub}
        navList={raceTabs()}
        withHistoryReplace={true}
      />
    </HorizontalScroll>
  );
};

export const RaceNavigationTabs = withRouter(RaceNavigationTabsComponent);
