import { PaymentTrackerStore, AnyStore, DisposersStore, BooleanStore } from 'stores';

import { SmartLink } from '../types';
import { ConfirmationStore } from './confirmationStore';
import { CouponsStore } from './coupons';
import { DisabledCustomFields } from './disabledCustomFields';
import { DisabledRegistrationFields } from './disabledRegistrationFields';
import { PaymentStore } from './payment';
import { RacerRegistration } from './racerRegistration';
import { ServiceFeeStore } from './serviceFee.store';
import { StepperStore } from './stepper';
import { TransferStore } from './transfer';

const paymentTrackerStore = new PaymentTrackerStore();

const displayModalStore = new AnyStore(false);

/**
 * @description
 * disables custom fields in the form
 * by field id
 * used for the invited user(confirmation)
 */
const disabledCustomFieldsStore = new DisabledCustomFields();

/**
 * @description
 * disables registration fields (not custom fields) in the form
 * by field id
 * used for the edit registration
 */
const disabledRegistrationFieldsStore = new DisabledRegistrationFields();

const disposers = new DisposersStore();
const wavesStore = new AnyStore<WaveType[]>();

const distanceStore = new AnyStore<DistanceType>();
const distanceProductStore = new AnyStore<SelectedRaceProduct[]>();
const raceStore = new AnyStore<RacesType>();

/**
 * @description
 * Used for the dropdown selector
 */
const raceDistancesListStore = new AnyStore<DistanceType[]>();

/**
 * @description
 * Coupon data loaded and stores by coupon codes
 */
const couponsStore = new CouponsStore();

/**
 * @description
 * Store for sign in modal that can be triggered when inputted existed email
 */
const signInModalStore = new BooleanStore();

/**
 * @description
 * Leader email already taken,
 * CHECKS PERFORMED ONLY FOR LEADER EMAIL
 */
const emailExistsStore = new BooleanStore();

const stepperStore = new StepperStore();

/**
 * @description
 * payment settings and current payment data
 * used for the PaymentFrame, to perform actual payment
 * 'current' means that it is active payment that user currently filling in
 *
 */
const currentPaymentStore = new PaymentStore();

/**
 * @description
 * is payment popup opened
 */
const isPaymentFrameOpened = new BooleanStore();
const isPaymentSuccessfullyCompleted = new BooleanStore(false);
const isPaymentSucceeded = new AnyStore('');

const isOfflineStore = new BooleanStore();

const confirmationStore = new ConfirmationStore();
const transferStore = new TransferStore();

const newSquadModalStore = new AnyStore<{ isOpen: boolean; id: string }>({ isOpen: false, id: '' });
const squadsStore = new AnyStore<AnyObject[]>();
const registrationClosedModalStore = new BooleanStore();
const racerRegistrationStore = new RacerRegistration();

const editRegistrationIdStore = new AnyStore<number>();
const smartLinkStore = new AnyStore<SmartLink>();

const serviceFeeStore = new ServiceFeeStore();

export {
  distanceProductStore,
  paymentTrackerStore,
  disabledCustomFieldsStore,
  couponsStore,
  disposers,
  distanceStore,
  raceStore,
  raceDistancesListStore,
  signInModalStore,
  stepperStore,
  emailExistsStore,
  currentPaymentStore,
  isOfflineStore,
  isPaymentFrameOpened,
  isPaymentSuccessfullyCompleted,
  isPaymentSucceeded,
  confirmationStore,
  wavesStore,
  CouponsStore,
  displayModalStore,
  newSquadModalStore,
  squadsStore,
  serviceFeeStore,
  transferStore,
  registrationClosedModalStore,
  racerRegistrationStore,
  disabledRegistrationFieldsStore,
  editRegistrationIdStore,
  smartLinkStore,
};
