import { t } from 'utils';

import { Distance } from 'models';

export const getTicketTitle = (ticket: AnyObject, unionTitle: string): string => {
  if (!ticket.distance) {
    return `${unionTitle.toUpperCase()} ${ticket.participating_year}`;
  }

  const model = new Distance(ticket.distance);
  return `${model.value.name} ${ticket.participating_year}`;
};

export const getStatus = (won: boolean) => {
  return won ? t.staticAsString('xtriTab.table.stateUsed') : t.staticAsString('xtriTab.table.stateValid');
};
