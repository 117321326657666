import { toJS } from 'mobx';
import { UserType } from 'typings/old-types/UserType';

import { ROUTER } from 'src/constants';
import { User } from 'src/models';
import { sessionStore } from 'src/stores';

import { fireToast, history, t, validation } from 'utils';

import { ACCOUNT_INFO_FORM_ID } from '../../constants';

import { accountInfoMapper, settingsFormData } from '../../utils';

import { FrontendData } from '../../types';
import { userFieldsType } from '../../validation';

export const updateAccountInfo = async (): Promise<void> => {
  const formData: UserType = toJS(settingsFormData()) as UserType;

  const mappedData = accountInfoMapper.mapTo(formData as FrontendData);
  const validationData = accountInfoMapper.mapToValidation(mappedData);

  if (validation(ACCOUNT_INFO_FORM_ID, validationData, userFieldsType)) {
    const user = sessionStore.getUserData();

    history.push(ROUTER.PROFILE);
    fireToast(t.staticAsString('toast.profileUpdated'), 'success', '', 'top-right');
    return;
  } else {
    fireToast(t.staticAsString('toast.error.text'), 'error', '', 'top-right');
  }
};
