import { Add } from './Add';
import { AlertClose } from './AlertClose';
import { ArrowDown } from './ArrowDown';
import { ArrowDownSmall } from './ArrowDownSmall';
import { ArrowFilterDown } from './ArrowFilterDown';
import { ArrowRight } from './ArrowRight';
import { ArrowRounded } from './ArrowRounded';
import { ArrowRoundedRight } from './ArrowRoundedRight';
import { Bib } from './Bib';
import { BurgerMenu } from './BurgerMenu';
import { Check } from './Check';
import { CircleDown } from './CircleDown';
import { CircleDownArrow } from './CircleDownArrow';
import { CircleUpArrow } from './CircleUpArrow';
import { Close } from './Close';
import { CloseIcon } from './CloseIcon';
import { CloseLight } from './CloseLight';
import { Controls } from './Controls';
import { Cross } from './Cross';
import { CrossClose } from './CrossClose';
import { CrossGray } from './CrossGray';
import { CrossThin } from './CrossThin';
import { DateCalendar } from './DateCalendar';
import { Delete } from './Delete';
import { Distance } from './Distance';
import { DownloadReceipt } from './DownloadReceipt';
import { Edit } from './Edit';
import { ErrorRed } from './ErrorRed';
import { ExportFinisherDiploma } from './ExportFinisherDiploma';
import { Facebook } from './Facebook';
import { Favourite } from './Favourite';
import { Globe } from './Globe';
import { GoToRace } from './GoToRace';
import { HeaderAccountLogOut } from './HeaderAccountLogOut';
import { HeaderAccountProfile } from './HeaderAccountProfile';
import { HeaderAccountSettings } from './HeaderAccountSettings';
import { Instagram } from './Instagram';
import { Linkedin } from './Linkedin';
import { List } from './List';
import { PulseLoader } from './Loader';
import { Location, AltLocation } from './Location';
import { LogoUkraine } from './Logo';
import { Map } from './Map';
import { MasterCard } from './MasterCard';
import { More } from './More';
import { Navigate } from './Navigate';
import { NavigateLeft } from './NavigateLeft';
import { NavigateRight } from './NavigateRight';
import { NoSportType } from './NoSportType';
import { Otillo } from './Otillo';
import { PinEllipse } from './PinEllipse';
import { Place } from './Place';
import { RaceFacebook } from './RaceFacebook';
import { RaceInstagram } from './RaceInstagram';
import { RaceLinkedin } from './RaceLinkedin';
import { RaceTwitter } from './RaceTwitter';
import { Remove } from './Remove';
import { Search } from './Search';
import { SearchYellow } from './SearchYellow';
import { Settings } from './Settings';
import { StripeWordmark } from './StripeWordmark';
import { Swish } from './Swish';
import { ThreeDots } from './ThreeDots';
import { Time } from './Time';
import { TransferRegistration } from './TransferRegistration';
import { Twitter } from './Twitter';
import { UploadImage } from './UploadImage';
import { Visa } from './Visa';
import { Xtri } from './Xtri';
import { ZoomMinus, ZoomPlus } from './Zoom';

export const Icons = {
  Add,
  AlertClose,
  ArrowDown,
  ArrowDownSmall,
  ArrowRounded,
  Bib,
  BurgerMenu,
  CircleDownArrow,
  CircleUpArrow,
  CloseIcon,
  CloseLight,
  Controls,
  CrossGray,
  CrossClose,
  CrossThin,
  DateCalendar,
  Delete,
  Distance,
  DownloadReceipt,
  Edit,
  ExportFinisherDiploma,
  Favourite,
  Globe,
  GoToRace,
  Location,
  AltLocation,
  More,
  Navigate,
  Place,
  SearchYellow,
  Settings,
  ThreeDots,
  ArrowRoundedRight,
  ZoomMinus,
  ZoomPlus,
  PinEllipse,
  ArrowFilterDown,
  Time,
  TransferRegistration,
  Cross,
  Search,
  NavigateLeft,
  NavigateRight,
  HeaderAccountSettings,
  HeaderAccountProfile,
  HeaderAccountLogOut,
  Linkedin,
  Facebook,
  Instagram,
  Map,
  Xtri,
  UploadImage,
  Otillo,
  Twitter,
  Check,
  Visa,
  Swish,
  MasterCard,
  Remove,
  List,
  RaceFacebook,
  RaceTwitter,
  RaceLinkedin,
  ErrorRed,
  PulseLoader,
  Close,
  RaceInstagram,
  NoSportType,
  LogoUkraine,
  CircleDown,
  ArrowRight,
  StripeWordmark,
};
