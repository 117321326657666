import { get } from 'lodash';
import { AppleAuthError } from 'modules/auth';

import { LOGIN } from 'src/constants';

import { fireToast, history, t } from 'utils';

import { authService } from 'services';

import { progressStore } from 'stores';

const appleLoginAction = async (responseData, closeModal, redirectLink?: string) => {
  try {
    progressStore.log(LOGIN, 'progress');
    const errorCode = get(responseData, 'error.error');
    if (errorCode) {
      switch (errorCode) {
        case 'popup_closed_by_user':
          return;
        default:
          return fireToast(AppleAuthError(), 'error', t.staticAsString('toast.cantGetAppleUserData'), 'top-right', 0, false);
      }
    }

    const auth_code = get(responseData, 'authorization.code');
    const firstname = get(responseData, 'user.name.firstName');
    const lastname = get(responseData, 'user.name.lastName');

    const isLogged = await authService.loginApple({
      auth_code,
      firstname,
      lastname,
    });

    if (isLogged && closeModal) {
      return closeModal();
    }

    if (isLogged && !closeModal) {
      return redirectLink ? history.push(redirectLink) : history.safeGoBack();
    }
    return fireToast(AppleAuthError(), 'error', t.staticAsString('toast.cantGetAppleUserData'), 'top-right', 0, false);
  } catch (e) {
    return fireToast(AppleAuthError(), 'error', t.staticAsString('toast.cantGetAppleUserData'), 'top-right', 0, false);
  } finally {
    progressStore.log(LOGIN, 'completed');
  }
};

const facebookLoginAction = async (token: string, closeModal, redirectLink?: string): Promise<any> => {
  progressStore.log(LOGIN, 'progress');
  const isSignUp = await authService.loginFb(token);
  progressStore.log(LOGIN, 'completed');

  if (isSignUp && closeModal) {
    return closeModal();
  }

  if (isSignUp && !closeModal) {
    return redirectLink ? history.push(redirectLink) : history.safeGoBack();
  }
  return fireToast(t.staticAsString('toast.cantGetUserData'), 'error', '', 'top-right');
};

const googleLoginAction = async (token: string, closeModal, redirectLink?: string): Promise<any> => {
  progressStore.log(LOGIN, 'progress');
  const isSignUp = await authService.loginGoogle(token);
  progressStore.log(LOGIN, 'completed');
  if (isSignUp && closeModal) {
    return closeModal();
  }

  if (isSignUp && !closeModal) {
    return redirectLink ? history.push(redirectLink) : history.safeGoBack();
  }
  return fireToast(t.staticAsString('toast.cantGetGoogleUserData'), 'error', '', 'top-right');
};

export { appleLoginAction, facebookLoginAction, googleLoginAction };
