export const INITIALIZE_DISTANCE = 'REGISTRATION_INITIALIZE_DISTANCE';
export const INITIALIZE_BASE_DATA = 'REGISTRATION_INITIALIZE_BASE_DATA';
export const CHECK_REGISTRATION_STATUS = 'CHECK_REGISTRATION_STATUS';
export const GET_REGISTRATION_RACERS = 'GET_REGISTRATION_RACERS';

/**
 * @description
 * enables un
 */
export const SUBMIT_IT_PROGRESS = 'REGISTRATION_SUBMIT_IT_PROGRESS';
/**
 * @description
 * form wrapper for the registration form
 * group registration members, registration leader, team members
 */
export const REGISTRATION_FORM = 'REGISTRATION_FORM';

/**
 * @description
 * form wrapper for the distance selectors
 */
export const DISTANCES_FORM = 'REGISTRAION_DISTANCES_FORM';

/**
 * @description
 * progressStore key for disabling coupon apply button
 */
export const COUPON_LOADING = 'REGISTRAION_COUPON_LOADING';
