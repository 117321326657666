import { max } from 'lodash';
import { computed } from 'mobx';

import { Wave } from 'src/models';
import { t } from 'src/utils';

import { wavesStore } from '../../stores';

import { formDataSelectors } from '../formDataSelectors';
import { mainConditions } from '../mainConditions';

const waveOptions = computed((): NewSelectItemType[] => {
  const waves = wavesStore.value || [];

  const models = waves.map((wave) => new Wave(wave));
  const registrationSeletedWaves = formDataSelectors.fetchWavesUsage();

  return models.map((wave) => {
    const maxMembers = wave.value.max_members;
    const racersCount = wave.value.racers_count || 0;
    const teamsCount = wave.value.teams_count || 0;
    const selectedCount = registrationSeletedWaves[wave.value.id] || 0;
    const usedCount = mainConditions.isTeam.get() ? teamsCount : racersCount;

    let isDisabled = false;
    let label = wave.value.name;

    if (maxMembers) {
      let availableSlots = maxMembers - (usedCount + selectedCount);
      if (wave.value.srl_slots_count && wave.value.srl_slots_used_count) {
        const diff = wave.value.srl_slots_count - wave.value.srl_slots_used_count;
        availableSlots -= diff;
      }

      if (availableSlots < 0) {
        availableSlots = 0;
      }

      isDisabled = availableSlots <= 0;

      if (isDisabled) {
        label = t.staticAsString('registration.waveSelectOptionNoSlots', { title: wave.value.name });
      } else {
        label = t.staticAsString('registration.waveSelectOption', { title: wave.value.name, count: max([0, availableSlots]) });
      }
    }

    return {
      key: wave.value.id,
      value: wave.value.id,
      label: label,
      disabled: isDisabled,
    };
  });
});

/**
 * @description
 * Unlike isEnabled, checks is there options that are not disalbed
 */
const isWaveOptionAvailable = computed((): boolean => {
  return waveOptions.get().some((option) => !option.disabled);
});

export { waveOptions, isWaveOptionAvailable };
