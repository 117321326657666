import { Observer } from 'mobx-react';
import { AvatarUpload, AvatarModalComponent, useLoadPreviewAvatar } from 'modules/Shared/AvatarAccountUpload';
import { FC, useEffect, useRef } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Dropdown } from 'src/styledComponents/Dropdown';
import { PhoneField } from 'src/styledComponents/PhoneField';
import { SeparatedDateField } from 'src/styledComponents/SeparatedDateField';
import { TextField } from 'src/styledComponents/TextField';

import { Form, InputWrapper } from 'components/form';

import { generateGenderOptions, t } from 'utils';

import { countriesStore, form, progressStore } from 'stores';

import FormControls from '../../components/FormControls';

import { ACCOUNT_INFO_FORM_ID, AVATAR_KEY } from '../../constants';

import { mountAccountInfo, unmountAccountInfo, updateAccountField, updateAccountInfo } from '../../actions';

import { useDebounceLiveUpdate } from '../../hooks';

type Props = {};

const FormWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  form > div:not(:last-child) {
    margin-bottom: 32px;
  }
  form span[disabled] {
    color: inherit;
  }
`;

const AvatarUploadWrapper = styled.div`
  margin-top: 48px;
  @media (min-width: ${RESOLUTIONS.small}px) {
    margin-left: 30px;
  }
`;

export const AccountInformationForm: FC<Props> = () => {
  const debounceLiveUpdate = useDebounceLiveUpdate(updateAccountField);
  const liveUpdateBirth = useDebounceLiveUpdate(updateAccountField, 500);
  const { isShowAvatarModal, imagePreviewUrl, closeModal, handleChangeFile } = useLoadPreviewAvatar();
  const didChangeRef = useRef<boolean>(false);

  useEffect(() => {
    mountAccountInfo();
    return () => {
      unmountAccountInfo();
    };
  }, []);

  const changePreviewUrl = (image: string) => {
    form.onChange(ACCOUNT_INFO_FORM_ID, AVATAR_KEY, image);
    handleLiveUpdate({ name: [AVATAR_KEY] });
  };

  const handleLiveUpdate = (value: AnyObject) => debounceLiveUpdate(value);

  const handleOnBlur = ({ target }) => {
    const changes = { name: [target?.name], value: target?.value };

    handleLiveUpdate(changes);
  };

  return (
    <Observer>
      {() => {
        const avatar = form.fetch<string>(ACCOUNT_INFO_FORM_ID, 'avatar');
        const allCountries = countriesStore.valuesForDropdown();
        const allNationalities = countriesStore.valuesForNationalityDropdown();
        const genderOptions = generateGenderOptions();
        const isLoadingProcess = progressStore.isLoading(ACCOUNT_INFO_FORM_ID);
        return (
          <TestAnchorContext.Provider value={{ container: 'Profile.Account-Information' }}>
            <AvatarModalComponent
              open={isShowAvatarModal}
              src={imagePreviewUrl}
              changePreviewUrl={changePreviewUrl}
              closeModal={closeModal}
            />
            <AvatarUploadWrapper>
              <AvatarUpload avatar={avatar} onChangeFile={handleChangeFile} />
            </AvatarUploadWrapper>
            <FormWrapper>
              <Form id={ACCOUNT_INFO_FORM_ID} cleanErrorOnChange disableAutofill>
                <InputWrapper
                  name='firstname'
                  settings={{
                    label: t.staticAsString('settings.firstName'),
                    placeholder: t.staticAsString('settings.firstName'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    onBlur: handleOnBlur,
                  }}
                  Component={TextField}
                />
                <InputWrapper
                  name='lastname'
                  settings={{
                    label: t.staticAsString('settings.lastName'),
                    placeholder: t.staticAsString('settings.lastName'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    onBlur: handleOnBlur,
                  }}
                  Component={TextField}
                />
                <InputWrapper
                  name='address'
                  settings={{
                    label: t.staticAsString('settings.address'),
                    placeholder: t.staticAsString('settings.address'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    onBlur: handleOnBlur,
                  }}
                  Component={TextField}
                />
                <InputWrapper
                  name='post_code'
                  settings={{
                    label: t.staticAsString('settings.post_code'),
                    placeholder: t.staticAsString('settings.post_code'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    onBlur: handleOnBlur,
                  }}
                  Component={TextField}
                />

                <InputWrapper
                  name='city'
                  settings={{
                    label: t.staticAsString('settings.city'),
                    placeholder: t.staticAsString('settings.city'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    onBlur: handleOnBlur,
                  }}
                  Component={TextField}
                />

                <InputWrapper
                  name='country_id'
                  settings={{
                    label: t.staticAsString('settings.country'),
                    placeholder: t.staticAsString('settings.country'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    options: () => allCountries,
                    additional: { onChange: handleLiveUpdate },
                  }}
                  Component={Dropdown}
                />
                <InputWrapper
                  name='email'
                  settings={{
                    label: t.staticAsString('settings.email'),
                    placeholder: t.staticAsString('settings.email'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    onBlur: handleOnBlur,
                  }}
                  Component={TextField}
                />
                <InputWrapper
                  name='birthday'
                  settings={{
                    label: t.staticAsString('settings.birthday.redesign'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    additional: {
                      onChange: (e) => {
                        if (!didChangeRef.current) {
                          didChangeRef.current = true;
                          return;
                        }
                        liveUpdateBirth(e);
                      },
                    },
                  }}
                  Component={SeparatedDateField}
                />
                <InputWrapper
                  name='nationality_id'
                  settings={{
                    label: t.staticAsString('settings.nationality_id'),
                    placeholder: t.staticAsString('settings.nationality_id'),
                    required: true,
                    requiredMark: true,
                    options: () => allNationalities,
                    disabled: isLoadingProcess,
                    additional: { onChange: handleLiveUpdate },
                  }}
                  Component={Dropdown}
                />
                <InputWrapper
                  name='telephone'
                  settings={{
                    label: t.staticAsString('settings.phone_number'),
                    placeholder: t.staticAsString('settings.phone_number'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    onBlur: ({ name, e }) => {
                      const { target } = e;
                      handleLiveUpdate({ name: [name], value: target?.value });
                    },
                  }}
                  Component={PhoneField}
                />
                <InputWrapper
                  name='gender'
                  settings={{
                    label: t.staticAsString('settings.gender'),
                    placeholder: t.staticAsString('settings.gender'),
                    required: true,
                    requiredMark: true,
                    disabled: isLoadingProcess,
                    options: () => genderOptions,
                    additional: { onChange: handleLiveUpdate },
                  }}
                  Component={Dropdown}
                />
              </Form>
              <FormControls showDeleteButton onSubmit={updateAccountInfo} />
            </FormWrapper>
          </TestAnchorContext.Provider>
        );
      }}
    </Observer>
  );
};
